import React from "react"

import Icon from "../../Icon"

const fileDirectory = process.env.FILE_BUCKET

const SermonLink = ({ file, icon, text, updatePlayer }) => {
    if (file !== "" && icon === "file-audio") {
        return (
            <div className="link-container">
                <button onClick={() => updatePlayer()} className="button-link">
                    <span>
                        <Icon prefix="far" name={icon} />
                        {text}
                    </span>
                </button>
            </div>
        )
    } else if (file !== "" && icon === "file-pdf") {
        return (
            <div className="link-container">
                <a
                    href={`${fileDirectory}/sermons/${file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon prefix="far" name={icon} />
                    {text}
                </a>
            </div>
        )
    } else {
        return null
    }
}

export default SermonLink
