import React from "react"
import AudioPlayer from "react-h5-audio-player"
import { Modal } from "react-bootstrap"

import "./styles.scss"

const Audio = ({ player, stopPlayer }) => {
    if (player.file !== undefined) {
        return (
            <Modal centered show={player.display} onHide={stopPlayer} size="lg">
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 text-center audio-heading">
                            <h5>{player.name}</h5>
                        </div>
                    </div>
                    <AudioPlayer autoPlay src={player.file} />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary c3-primary"
                        onClick={stopPlayer}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    } else {
        return null
    }
}

export default Audio
