import React from "react"
import axios from "axios"

import Loader from "../Loader"
import Message from "../Message"
import Pagination from "../Pagination"
import Audio from "../Audio"

import BoxLayout from "../Box"
import BoxTitle from "../Box/title"
import BoxDate from "../Box/date"
import SermonLink from "../Box/Links/sermon"

const sermonsAPI = process.env.API_SERMONS
const fileDirectory = process.env.FILE_BUCKET

class SermonList extends React.Component {
    constructor(props) {
        super(props)
        this.sermonList = React.createRef()
        this.state = {
            sermons: [],
            page: 1,
            perPage: 20,
            isLoading: true,
            message: undefined,
            player: {
                file: undefined,
                display: false,
            },
        }
    }

    componentDidMount = () => {
        const { page, perPage } = this.state
        this.getSermons(page, perPage)
    }

    getSermons = (page, perPage) => {
        axios
            .get(`${sermonsAPI}?page=${page}&per-page=${perPage}`)
            .then((response) => {
                this.setState({
                    sermons: response.data.sermons,
                    total: response.data.total,
                    page: page,
                    isLoading: false,
                })
            })
            .catch((error) => {
                this.setState({
                    message: {
                        type: "danger",
                        text: error.response.data.message,
                    },
                    isLoading: false,
                })
            })
    }

    updatePlayer = (file, name) => {
        this.setState({
            player: {
                file: `${fileDirectory}/sermons/${file}`,
                display: true,
                name: name,
            },
        })
    }

    stopPlayer = () => {
        this.setState({
            player: {
                file: undefined,
                display: false,
                name: undefined,
            },
        })
    }

    updateSermonList = (page, perPage) => {
        window.scrollTo(0, this.sermonList.current.offsetTop - 120)
        this.getSermons(page, perPage)
    }

    render() {
        const {
            isLoading,
            message,
            sermons,
            page,
            perPage,
            total,
            player,
        } = this.state
        let sermonList = sermons.map((sermon) => (
            <BoxLayout key={sermon.id}>
                <BoxTitle name={sermon.name} />
                <BoxDate date={sermon.date} />
                <SermonLink
                    file={sermon.audioHigh}
                    updatePlayer={() =>
                        this.updatePlayer(sermon.audioHigh, sermon.name)
                    }
                    icon="file-audio"
                    text="Audio - High Quality"
                />
                <SermonLink
                    file={sermon.audioLow}
                    updatePlayer={() =>
                        this.updatePlayer(sermon.audioLow, sermon.name)
                    }
                    icon="file-audio"
                    text="Audio - Low Quality"
                />
                <SermonLink file={sermon.notes} icon="file-pdf" text="Notes " />
            </BoxLayout>
        ))
        if (isLoading === true) {
            return <Loader />
        } else if (message) {
            return <Message type={message.type} message={message.text} />
        } else {
            return (
                <>
                    <Audio
                        player={player}
                        stopPlayer={() => this.stopPlayer()}
                    />
                    <div className="row" ref={this.sermonList}>
                        {sermonList}
                    </div>
                    <div className="row">
                        <Pagination
                            page={page}
                            perPage={perPage}
                            total={total}
                            updateFunc={this.updateSermonList}
                        />
                    </div>
                </>
            )
        }
    }
}

export default SermonList
