import React from "react"

import Layout from "../components/layout"
import Page from "../components/Page/page"

import SermonList from "../components/Sermon/list"

class Sermons extends React.Component {
  render() {
    const {location} = this.props
    return (
      <Layout location={location}>
        <Page id="4" />
        <SermonList />
      </Layout>
    )
  }
}

export default Sermons
